<template>
  <v-parallax src="@/assets/img/layout/bg_01.jpg" id="parallax">
    <v-container>
      <div class="inner" :class="{ mobile: !$vuetify.breakpoint.smAndUp }">
        <!-- 1 -->
        <div class="title1">
          <h3>법인 재무 컨설팅</h3>
        </div>

        <!-- 2 -->
        <div class="title2">
          <h2>C.R Consulting</h2>
        </div>

        <!-- 3 -->
        <div class="title3">
          <h4>기업도 재무설계가 필요합니다.</h4>
        </div>

        <!-- 스크롤 다운 버튼 -->
        <div class="bot">
          <div class="scroll-down" @click="clickScrollDown()">
            <span>Scroll Down</span>
          </div>
        </div>
      </div>
    </v-container>
  </v-parallax>
</template>

<script>
export default {
  methods: {
    clickScrollDown() {
      let element = null

      element = document.getElementById('financial-consulting')
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
#parallax {
  height: calc(100vh - 68px) !important;
  margin-top: -68px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .inner {
    text-align: center;

    .title1 {
      background-color: $color-white;
      width: 216px;
      height: 49px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 24px;

      h3 {
        font-size: 25px;
        color: $color-black2;
        line-height: 27px;
      }
    }

    .title2 {
      // width: 600px;
      height: 124px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;

      h2 {
        font-size: 100px;
        font-weight: 700 !important;
      }
    }

    .title3 {
      h4 {
        font-size: 24px;
        font-weight: 300 !important;
      }
    }

    &.mobile {
      .title1 {
        width: 140px;
        height: 40px;

        h3 {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .title2 {
        height: 40px;

        h2 {
          font-size: 36px;
        }
      }

      .title3 {
        h4 {
          font-size: 16px;
        }
      }
    }

    .bot {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 24px;
      display: flex;
      justify-content: center;

      .scroll-down {
        border: 1px solid $color-white;
        width: 114px;
        height: 35px;
        cursor: pointer;

        span {
          font-size: 15px;
          font-weight: 600;
          line-height: 35px;
        }
      }
    }
  }
}
</style>
